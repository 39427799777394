import { useEffect, useRef, useState } from 'react';
import QuickFiltersView from './QuickFilters.View';
import { QuickFilter, QuickFiltersProps } from './types';
import { useQuery } from 'hooks';
import { CreativeAdStatus, CreativeType } from '../../types';

const DEFAULT_CREATIVE_TYPES = [CreativeType.VIDEO, CreativeType.PHOTO];
const DEFAULT_STATUS = [CreativeAdStatus.ACTIVE, CreativeAdStatus.INACTIVE];
const DEFAULT_BEST_TO_WORST_VALUES = ['5', '4', '3', '2', '1'];

const MODAL_TIMEOUT = 250;

const formatFilter = (filter: string) => (filter ?? '').split(',');

/* when filters are composed as a csv type of value, this handles it */
const formatSpecialFilters = (
  filter: string,
  splitCondition: CreativeType.VIDEO | CreativeAdStatus.ACTIVE,
) => {
  const split = formatFilter(filter);
  const splitIndex = split.indexOf(splitCondition);

  if (splitIndex === -1) return [split.join(',')];

  const withoutCondition = [...split.slice(0, splitIndex), ...split.slice(splitIndex + 1)];
  return [splitCondition, withoutCondition.join(',')];
};

const QuickFilters = (props: QuickFiltersProps) => {
  const { getQueryParams } = useQuery();
  const {
    ctr: ctrQuery,
    roas: roasQuery,
    thumbstop: thumbstopQuery,
    status: statusQuery,
    type: typeQuery,
  } = getQueryParams<QuickFilter>();
  const anyPreSelected = Boolean(ctrQuery || roasQuery || thumbstopQuery);
  const { open, onClose, onOpen, onSaveSelection } = props;
  const previousValues = useRef<any>(null);
  const [roas, setROAS] = useState<string[]>(DEFAULT_BEST_TO_WORST_VALUES);
  const [ctr, setCTR] = useState<string[]>(DEFAULT_BEST_TO_WORST_VALUES);
  const [thumbstop, setThumbstop] = useState<string[]>(DEFAULT_BEST_TO_WORST_VALUES);
  const [status, setStatus] = useState<string[]>(DEFAULT_STATUS);
  const [creativeTypes, setCreativeTypes] = useState<string[]>(DEFAULT_CREATIVE_TYPES);

  const onSelectAllClick = () => {
    setROAS([...DEFAULT_BEST_TO_WORST_VALUES]);
    setCTR([...DEFAULT_BEST_TO_WORST_VALUES]);
    setThumbstop([...DEFAULT_BEST_TO_WORST_VALUES]);
    setStatus([...DEFAULT_STATUS]);
    setCreativeTypes([...DEFAULT_CREATIVE_TYPES]);
  };

  const onClearAllClick = () => {
    setROAS([]);
    setCTR([]);
    setThumbstop([]);
    setStatus([]);
    setCreativeTypes([]);
  };

  const onCancelClick = () => {
    setTimeout(() => {
      setROAS(previousValues.current.roas);
      setCTR(previousValues.current.ctr);
      setThumbstop(previousValues.current.thumbstop);
      setStatus(previousValues.current.status);
      setCreativeTypes(previousValues.current.creativeTypes);
    }, MODAL_TIMEOUT);
    onClose();
  };

  const setPreviousValues = () => {
    previousValues.current = {
      roas,
      ctr,
      thumbstop,
      status,
      creativeTypes,
    };
  };

  const onSaveClick = () => {
    const filters: { [key in QuickFilter]?: string[] } = {
      roas: undefined,
      ctr: undefined,
      thumbstop: undefined,
      status: undefined,
      type: undefined,
    };

    if (roas.length > 0) filters.roas = roas;
    if (ctr.length > 0) filters.ctr = ctr;
    if (thumbstop.length > 0) filters.thumbstop = thumbstop;
    if (status.length > 0) filters.status = status;
    if (creativeTypes.length > 0) filters.type = creativeTypes;

    onSaveSelection(filters);
    setPreviousValues();
    onClose();
  };

  /* sets previous values, when calling cancel button */
  useEffect(() => {
    if (open) {
      previousValues.current = {
        roas: [...roas],
        ctr: [...ctr],
        thumbstop: [...thumbstop],
        status: [...status],
        creativeTypes: [...creativeTypes],
      };
    }
  }, [open]);

  /* sets values when mounted, if previously selected from filter, otherwise it is all selected by default */
  useEffect(() => {
    if (anyPreSelected) {
      setROAS(roasQuery ? formatFilter(roasQuery) : []);
      setCTR(ctrQuery ? formatFilter(ctrQuery) : []);
      setThumbstop(thumbstopQuery ? formatFilter(thumbstopQuery) : []);
      setStatus(statusQuery ? formatSpecialFilters(statusQuery, CreativeAdStatus.ACTIVE) : []);
      setCreativeTypes(typeQuery ? formatSpecialFilters(typeQuery, CreativeType.VIDEO) : []);
    }
  }, []);

  const setters = {
    roas: setROAS,
    ctr: setCTR,
    thumbstop: setThumbstop,
    status: setStatus,
    type: setCreativeTypes,
  };

  const filters = {
    roas,
    ctr,
    thumbstop,
    status,
    type: creativeTypes,
  };

  const mapping = [
    [roas, DEFAULT_BEST_TO_WORST_VALUES],
    [ctr, DEFAULT_BEST_TO_WORST_VALUES],
    [thumbstop, DEFAULT_BEST_TO_WORST_VALUES],
    [status, DEFAULT_STATUS],
    [creativeTypes, DEFAULT_CREATIVE_TYPES],
  ] as const;

  const isAllSelected = mapping.every(([filter, defaults]) =>
    defaults.every((value) => filter.includes(value)),
  );

  const isAnySelected = mapping.some(([filter]) => filter.length > 0);

  const selectedFilters = mapping.reduce((acc, [filter]) => acc + Number(filter.length > 0), 0);

  const label = `Quick Filters / ${isAllSelected ? 'All' : selectedFilters}`;

  return (
    <QuickFiltersView
      open={open}
      onOpen={onOpen}
      onClose={onClose}
      filters={filters}
      setters={setters}
      label={label}
      onSelectAllClick={onSelectAllClick}
      onClearAllClick={onClearAllClick}
      allSelected={isAllSelected}
      anySelected={isAnySelected}
      onSaveClick={onSaveClick}
      onCancelClick={onCancelClick}
    />
  );
};

export default QuickFilters;

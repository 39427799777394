import { Text, CheckboxGroup, colors } from '@innovationdepartment/proxima-ui';
import styled from '@emotion/styled';
import { QuickFiltersViewProps } from '../types';
import { bestToWorstRenderer, creativeTypeRenderer, statusRenderer } from './filterRenderers';
import { CreativeAdStatus, CreativeType } from 'components/PerformanceAds/Layout/types';
import { useFeatureFlag } from 'hooks';
import { FeatureFlags } from 'types/featureFlag';

const QuickFiltersFiltersWrapper = styled.div`
  margin: 0 -16px 0 -16px;
  display: flex;
  align-items: flex-start;
  padding: 0 16px 16px;
  gap: 16px;
  overflow-x: auto;
`;

type FilterDefinition = {
  key: keyof QuickFiltersViewProps['filters'];
  label: string;
  width?: number;
  values: string[];
  renderer: (value: string) => JSX.Element;
};

const getFiltersToRender = (flags: FeatureFlags) => {
  const { performanceLibraryV2 } = flags;
  const FILTER_DEFINITIONS = [
    performanceLibraryV2 && {
      key: 'type',
      width: 192,
      label: 'Creative Type',
      values: [CreativeType.VIDEO, CreativeType.PHOTO],
      renderer: creativeTypeRenderer,
    },
    performanceLibraryV2 && {
      key: 'status',
      width: 192,
      label: 'Status',
      values: [CreativeAdStatus.ACTIVE, CreativeAdStatus.INACTIVE],
      renderer: statusRenderer,
    },
    {
      key: 'roas',
      width: 192,
      label: 'ROAS PERFORMANCE',
      values: ['5', '4', '3', '2', '1'],
      renderer: bestToWorstRenderer,
    },
    {
      key: 'ctr',
      width: 192,
      label: 'CTR PERFORMANCE',
      values: ['5', '4', '3', '2', '1'],
      renderer: bestToWorstRenderer,
    },
    {
      key: 'thumbstop',
      width: 192,
      label: 'THUMBSTOP PERFORMANCE',
      values: ['5', '4', '3', '2', '1'],
      renderer: bestToWorstRenderer,
    },
  ].filter(Boolean) as FilterDefinition[];

  return FILTER_DEFINITIONS;
};

const FilterWrapper = styled.div<{ width?: number }>`
  display: flex;
  justify-content: stretch;
  flex-direction: column;
  gap: 8px;
  min-width: ${({ width = 160 }) => `${width}px`};

  & > div div {
    width: 100%;
  }
`;

const FilterLabel = styled.div`
  border-bottom: 1px solid ${colors.neutral10};
  background-color: ${colors.neutral0};
  margin: 0 -16px;
  padding: 6px 24px;
`;

const QuickFiltersFilters = (props: QuickFiltersViewProps) => {
  const { filters, setters } = props;
  // TODO(Jenky): Flags are temporary, should remove once this feature is tested
  const flags = useFeatureFlag();

  const filtersToRender = getFiltersToRender(flags);

  return (
    <QuickFiltersFiltersWrapper>
      {filtersToRender.map(({ label, key, width, values, renderer }) => (
        <FilterWrapper width={width} key={label}>
          <FilterLabel>
            <Text variant="tag2Semibold" color="neutral70">
              {label}
            </Text>
          </FilterLabel>
          <CheckboxGroup
            key={filters[key].join(',')}
            selected={filters[key]}
            onChange={setters[key]}
            render={(args) => renderer(args.value)}
            options={values.map((value) => ({ value }))}
          />
        </FilterWrapper>
      ))}
    </QuickFiltersFiltersWrapper>
  );
};

export default QuickFiltersFilters;

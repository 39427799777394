import styled from '@emotion/styled';

const FilterBaseFiltersWrapper = styled.div`
  margin: 0 -16px 0 -16px;
  display: flex;
  align-items: flex-start;
  padding: 0 16px 16px;
  gap: 16px;
  overflow-x: auto;
`;

export default FilterBaseFiltersWrapper;

import { ButtonWithPopup } from '@innovationdepartment/proxima-ui';

import FilterBaseFiltersWrapper from './Layout/Wrappers/FilterBaseFiltersWrapper';
import FilterBaseWrapper from './Layout/Wrappers/FilterBaseWrapper';
import FilterBaseHeader from './Layout/FilterBaseHeader';
import FilterBaseActions from './Layout/FilterBaseActions';

import { FilterBaseProps } from './types';

const FilterBaseView: React.FC<FilterBaseProps & { children?: React.ReactNode }> = (props) => {
  const { label, icon, onClose, onOpen, open, children } = props;

  return (
    <ButtonWithPopup startIcon={icon} label={label} onOpen={onOpen} onClose={onClose} open={open}>
      <FilterBaseWrapper>
        <FilterBaseHeader {...props} />
        <FilterBaseFiltersWrapper>{children}</FilterBaseFiltersWrapper>
        <FilterBaseActions {...props} />
      </FilterBaseWrapper>
    </ButtonWithPopup>
  );
};

export default FilterBaseView;

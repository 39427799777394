import { Button, colors } from '@innovationdepartment/proxima-ui';
import ButtonGroup from 'ui/ButtonGroup';

import styled from '@emotion/styled';
import { FilterBaseActionsProps } from '../../types';

const StyledButtonGroup = styled(ButtonGroup)`
  padding-top: 16px;
  border-top: 1px solid ${colors.neutral10};
`;

const FilterBaseActions = (props: FilterBaseActionsProps) => {
  const { onSaveClick, onCancelClick } = props;
  return (
    <StyledButtonGroup>
      <Button label="Cancel" onClick={onCancelClick} variant="outlined" />
      <Button label="Save" onClick={onSaveClick} />
    </StyledButtonGroup>
  );
};

export default FilterBaseActions;

const conversion: Record<number, string> = {
  5: 'Best',
  4: 'Good',
  3: 'Average',
  2: 'Below Average',
  1: 'Worst',
};
const reverseConversion: Record<number, string> = {
  1: 'Best',
  2: 'Good',
  3: 'Average',
  4: 'Below Average',
  5: 'Worst',
};

export default function formatQuentile(value: number, reverseOrder = false): string {
  return reverseOrder ? reverseConversion[value] : conversion[value];
}

import { ToggleSwitch, DatePicker } from '@innovationdepartment/proxima-ui';
import FiltersHeaderBarControls from './Layout/Filters/FiltersHeaderBarControls';
import { PerformanceAdsViewProps } from './types';
import BrandFilter from './Layout/Filters/BrandFilter';
import QuickFilters from './Layout/Filters/QuickFilters';

import styled from '@emotion/styled';
import CategoryFilter from './Layout/Filters/CategoryFilter';

const RightAligned = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const LeftAligned = styled(RightAligned)`
  justify-content: flex-start;
`;

const PerformanceAdsFilters = (props: PerformanceAdsViewProps) => {
  const {
    showFilters,
    onShowFilters,
    view,
    toggleView,
    viewOptions,
    onApplyFilters,
    dateRange,
    onDateRangeChange,
  } = props;
  return (
    <FiltersHeaderBarControls>
      <LeftAligned>
        <CategoryFilter
          open={showFilters.category}
          onSaveSelection={onApplyFilters}
          onOpen={() => onShowFilters('category')}
          onClose={() => onShowFilters('category', false)}
        />
        <BrandFilter
          open={showFilters.brand}
          onSaveSelection={onApplyFilters}
          onOpen={() => onShowFilters('brand')}
          onClose={() => onShowFilters('brand', false)}
        />
        <QuickFilters
          open={showFilters.performance}
          onSaveSelection={onApplyFilters}
          onOpen={() => onShowFilters('performance')}
          onClose={() => onShowFilters('performance', false)}
        />
      </LeftAligned>
      <RightAligned>
        <DatePicker
          disableFutureDates
          dateRange={dateRange}
          withRange
          showSelectWithDateRange={false}
          onDateRangeSelect={onDateRangeChange}
        />
        <ToggleSwitch options={viewOptions} value={view} onChange={toggleView} />
      </RightAligned>
    </FiltersHeaderBarControls>
  );
};

export default PerformanceAdsFilters;

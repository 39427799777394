import { CellRendererProps, FooterCellRendererProps } from '@innovationdepartment/proxima-ui';

export enum InsightsInterval {
  Day = 'day',
  Week = 'week',
  Month = 'month',
}

export enum InsightsMetric {
  Cpa = 'cpa',
  Roas = 'roas',
  Ctr = 'ctr',
  Cpc = 'cpc',
  Cpm = 'cpm',
  Cvr = 'cvr',
  Spend = 'spend',
}

export type AnchorOptions = {
  label: string;
  value: InsightsMetric;
  icon?: React.ReactNode;
};

export type IntervalOption = {
  label: string;
  value: InsightsInterval;
};

export type MetricCopy = {
  metric: InsightsMetric;
  title: string;
  copy: React.ReactNode;
};

export type InsightQueryStringParams = {
  key: string;
  value: InsightsMetric | InsightsInterval | string;
};

export type InsightsColumnData = {
  date: string;
  cpm: number;
  ctr: number;
  cpa: number;
  roas: number;
  cpc: number;
  cpaChange: {
    flavorCategoryCpaChange: number;
    brandCpaChange: number;
  };
  roasChange: {
    flavorCategoryRoasChange: number;
    brandRoasChange: number;
  };
};

export type HeatmapViewIntensity = 'light' | 'bright';

export type TrendsTableCellRendererProps<T> = CellRendererProps<T>;

export type TrendsFooterCellRendererProps<T> = FooterCellRendererProps<T>;

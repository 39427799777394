import { useEffect, useRef, useState } from 'react';
import { useToaster, Text } from '@innovationdepartment/proxima-ui';
import { useAudiencesApi, useShowSpinner } from 'hooks';
import AudienceSettingsModal from 'ui/Modals/AudienceSettingsModal';
import {
  AudienceSettingsSchema,
  SeedAudienceSchema,
  LookalikeAudienceSchema,
  GalaxySchema,
  CreateLookalikeAudienceSchema,
} from 'types/audiences';
import RenameSeedAudienceModal from 'ui/Modals/RenameSeedAudienceModal';
import RenameLookalikeAudienceModal from 'ui/Modals/RenameLookalikeAudienceModal';
import CreateSeedAudiencesModal from 'ui/Modals/CreateSeedAudiencesModal';
import DeleteSeedAudienceModal from 'ui/Modals/DeleteSeedAudienceModal';
import DeleteLookalikeAudienceModal from 'ui/Modals/DeleteLookalikeAudienceModal';
import CreateLookalikeAudienceModal from 'ui/Modals/CreateLookalikeAudienceModal';
import AudiencesView from './Audiences.View';
import useFacebook from 'hooks/useFacebook';
import { BrandIntegrationStatus } from 'types/integrations';

const Audiences = () => {
  const {
    loading: seedAudiencesLoading,
    getSeedAudiences,
    updateAudienceSettings,
    updateAudienceUpdateSettings,
    renameSeedAudience,
    renameLookalikeAudience,
    getAvailableGalaxies,
    deleteSeedAudience,
    deleteLookalikeAudience,
    createLookalikeAudience,
  } = useAudiencesApi();
  const { getMetaStatus } = useFacebook();
  const timeoutRef = useRef<number>(-1);
  const { showToaster } = useToaster();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [seedAudiences, setSeedAudiences] = useState<SeedAudienceSchema[]>([]);
  const [selectedSeedAudience, setSelectedSeedAudience] = useState<SeedAudienceSchema>();
  const [selectedLookalikeAudience, setSelectedLookalikeAudience] =
    useState<LookalikeAudienceSchema>();
  const [galaxies, setGalaxies] = useState<GalaxySchema[]>([]);

  /* modal flags */
  const [createSeedAudiencesOpen, setCreateSeedAudiencesOpen] = useState(false);
  const [renameSeedAudienceModalOpen, setRenameSeedAudienceModalOpen] = useState(false);
  const [createLookalikeAudiencesOpen, setCreateLookalikeAudiencesOpen] = useState(false);
  const [renameLookalikeAudienceModalOpen, setRenameLookalikeAudienceModalOpen] = useState(false);
  const [audienceSettingsModalOpen, setAudienceSettingsModalOpen] = useState(false);
  const [deleteSeedAudienceModalOpen, setDeleteSeedAudienceModalOpen] = useState(false);
  const [deleteLookalikeAudienceModalOpen, setDeleteLookalikeAudienceModalOpen] = useState(false);

  const loading = isLoading || seedAudiencesLoading;

  useShowSpinner({ show: loading });

  const fetchSeedAudiences = async () => {
    const response = await getSeedAudiences();
    if (response.status !== 200) return;

    const seedAudiencesData = response.data.seedAudiences as unknown as SeedAudienceSchema[];

    const seedAudiencesWithIntegrationTypes = seedAudiencesData.map((seedAudience) => {
      const { integrationAudiences } = seedAudience;

      const integrationTypes = integrationAudiences.map(
        (integrationAudience) => integrationAudience.integrationType,
      );

      const noDuplicateIntegrationTypes = [...new Set(integrationTypes)];

      return { ...seedAudience, integrationTypes: noDuplicateIntegrationTypes };
    });

    setSeedAudiences(seedAudiencesWithIntegrationTypes);
  };

  const fetchAvailableGalaxies = async () => {
    setIsLoading(true);
    const response = await getAvailableGalaxies();
    if (response.status === 200) {
      setGalaxies(response.data as GalaxySchema[]);
    }

    setIsLoading(false);
  };

  type SeedAudienceRowAction = 'delete' | 'rename' | 'settings' | 'createLookalike';
  const toggleSeedAudienceActionModal =
    (modal: SeedAudienceRowAction) => (row?: SeedAudienceSchema) => {
      const show = !!row;
      const seedAudienceModals = {
        delete: setDeleteSeedAudienceModalOpen,
        rename: setRenameSeedAudienceModalOpen,
        settings: setAudienceSettingsModalOpen,
        createLookalike: setCreateLookalikeAudiencesOpen,
      } satisfies { [key in SeedAudienceRowAction]: (show: boolean) => void };
      setSelectedSeedAudience(row);
      seedAudienceModals[modal](show);
    };

  type LookalikeAudienceRowAction = 'delete' | 'rename';
  const toggleLookalikeAudienceActionModal =
    (modal: LookalikeAudienceRowAction) => (row?: LookalikeAudienceSchema) => {
      const show = !!row;
      const lookalikeAudienceModals = {
        delete: setDeleteLookalikeAudienceModalOpen,
        rename: setRenameLookalikeAudienceModalOpen,
        create: setCreateSeedAudiencesOpen,
      };
      setSelectedLookalikeAudience(row);
      lookalikeAudienceModals[modal](show);
    };

  const handleDeleteSeedAudience = async () => {
    try {
      const seedAudienceId = selectedSeedAudience?.id;

      if (!seedAudienceId) {
        return;
      }

      await deleteSeedAudience(seedAudienceId);

      const deleteSeedAudienceMessage = (
        <Text variant="body2">
          Custom audience <Text variant="body2Semibold">{selectedSeedAudience?.name}</Text> deleted.
        </Text>
      );

      toggleSeedAudienceActionModal('delete')();
      showToaster({ message: deleteSeedAudienceMessage, variant: 'success' });

      await fetchSeedAudiences();
    } catch (e: any) {
      showToaster({ message: 'An error occurred. Please try again.', variant: 'error' });
    }
  };

  const handleDeleteLookalikeAudience = async () => {
    try {
      const lookalikeAudienceId = selectedLookalikeAudience?.id;

      if (!lookalikeAudienceId) {
        return;
      }

      await deleteLookalikeAudience({
        seedAudienceId: selectedLookalikeAudience.seedAudienceId,
        lookalikeAudienceId,
      });

      const deleteLookalikeAudienceMessage = (
        <Text variant="body2">
          Lookalike audience <Text variant="body2Semibold">{selectedLookalikeAudience?.name}</Text>{' '}
          deleted.
        </Text>
      );

      toggleLookalikeAudienceActionModal('delete')();
      showToaster({ message: deleteLookalikeAudienceMessage, variant: 'success' });

      await fetchSeedAudiences();
    } catch (e: any) {
      showToaster({ message: 'An error occurred. Please try again.', variant: 'error' });
    }
  };

  const toggleCreateSeedAudienceModal = (open: boolean = true) => {
    setCreateSeedAudiencesOpen(open);
  };

  const handleRenameSeedAudience = async (newName: string) => {
    try {
      const seedAudienceId = selectedSeedAudience?.id;

      if (!seedAudienceId) {
        return;
      }

      await renameSeedAudience({ seedAudienceId, newName });

      const renameMessage = (
        <Text variant="body2">
          <Text variant="body2Semibold">{selectedSeedAudience?.name}</Text> renamed to{' '}
          <Text variant="body2Semibold">{newName}</Text>
        </Text>
      );

      toggleSeedAudienceActionModal('rename')();
      showToaster({ message: renameMessage, variant: 'success' });

      await fetchSeedAudiences();
    } catch (e: any) {
      showToaster({ message: 'An error occurred. Please try again.', variant: 'error' });
    }
  };

  const handleRenameLookalikeAudience = async (newName: string) => {
    try {
      const lookalikeAudienceId = selectedLookalikeAudience?.id;

      if (!lookalikeAudienceId) {
        return;
      }

      await renameLookalikeAudience({
        seedAudienceId: selectedLookalikeAudience.seedAudienceId,
        lookalikeAudienceId,
        newName,
      });

      const renameMessage = (
        <Text variant="body2">
          <Text variant="body2Semibold">{selectedLookalikeAudience?.name}</Text> renamed to{' '}
          <Text variant="body2Semibold">
            {`Lookalike (${selectedLookalikeAudience.targetCountry}, ${Math.round(
              selectedLookalikeAudience.size * 100,
            )}%) - Proxima - ${newName}`}
          </Text>
        </Text>
      );

      toggleLookalikeAudienceActionModal('rename')();
      showToaster({ message: renameMessage, variant: 'success' });

      await fetchSeedAudiences();
    } catch (e: any) {
      showToaster({ message: 'An error occurred. Please try again.', variant: 'error' });
    }
  };

  const handleAudienceSettingsUpdate = async (audienceSettings: AudienceSettingsSchema) => {
    if (!selectedSeedAudience) return;

    const seedAudienceId = selectedSeedAudience.id;
    const { optedIntoUpdates } = audienceSettings;
    const updateDayFrequency = +audienceSettings.updateDayFrequency;
    const audienceUpdateSettings = {
      seedAudiences: {
        [seedAudienceId]: optedIntoUpdates,
      },
    };

    // Update settings related to auto-updating. Do this first so that we can get
    // the fully updated seed audience back in the other response.
    const audienceUpdateSettingsResponse =
      await updateAudienceUpdateSettings(audienceUpdateSettings);

    // Update settings for the audience.
    const audienceSettingsResponse = await updateAudienceSettings({
      seedAudienceId,
      updateDayFrequency,
    });

    if (audienceUpdateSettingsResponse.error || audienceSettingsResponse.error) {
      // TODO(Jenky): discuss with product
      const errorMsg =
        'Something happened when updating audience settings. Please try again later.';
      showToaster({ message: errorMsg, variant: 'error' });
    } else {
      const seedAudience = audienceSettingsResponse.data as SeedAudienceSchema;
      const message = (
        <Text variant="body2">
          Audience settings for <Text variant="body2Semibold">{seedAudience.name}</Text> updated.
        </Text>
      );

      toggleSeedAudienceActionModal('settings')();
      showToaster({
        message,
        variant: 'success',
      });

      setSeedAudiences(
        seedAudiences.map((seed) => {
          if (seed.id === selectedSeedAudience.id) {
            const { integrationAudiences } = seedAudience;

            const integrationTypes = integrationAudiences.map(
              (integrationAudience) => integrationAudience.integrationType,
            );

            const noDuplicateIntegrationTypes = [...new Set(integrationTypes)];

            return { ...seedAudience, integrationTypes: noDuplicateIntegrationTypes };
          }

          return seed;
        }),
      );
    }
  };

  const handleSeedAudiencesCreation = () => {
    fetchSeedAudiences();
    /* remove galaxies after modal is closed, that way we simulate that they're "processing" */
    clearTimeout(timeoutRef.current);
    timeoutRef.current = window.setTimeout(() => {
      fetchAvailableGalaxies();
    }, 250);
  };

  const handleLookalikeAudienceCreation = async (sizeData: CreateLookalikeAudienceSchema) => {
    try {
      const response = await createLookalikeAudience(sizeData);
      if (response.status === 201) {
        const lookalikeAudience = response.data as LookalikeAudienceSchema;

        showToaster({ message: 'New lookalike audience created', variant: 'success' });
        setSeedAudiences(
          seedAudiences.map((seed) => {
            if (seed.id === selectedSeedAudience?.id) {
              return {
                ...seed,
                lookalikeAudiences: [...seed.lookalikeAudiences, lookalikeAudience],
              };
            }

            return seed;
          }),
        );

        toggleSeedAudienceActionModal('createLookalike')();
        return;
      }
    } catch (err) {
      /* */
    }
    const errorMsg =
      'Something happened when creating your lookalike audiences. Please try again later.';
    showToaster({ message: errorMsg, variant: 'error' });
  };

  /* fetch available galaxies */
  useEffect(() => {
    fetchAvailableGalaxies();
  }, []);

  /* fetch all seed audiences */
  useEffect(() => {
    fetchSeedAudiences();
  }, []);

  const availableGalaxies = galaxies.length > 0;
  const isMetaDisconnected = getMetaStatus() === BrandIntegrationStatus.Disconnected;

  return (
    <>
      <AudiencesView
        seedAudiences={seedAudiences}
        onShowRenameSeedAudienceModal={toggleSeedAudienceActionModal('rename')}
        onShowRenameLookalikeAudienceModal={toggleLookalikeAudienceActionModal('rename')}
        onShowAudienceSettingsModal={toggleSeedAudienceActionModal('settings')}
        onShowCreateLookalikeAudienceModal={toggleSeedAudienceActionModal('createLookalike')}
        onShowCreateSeedAudiencesModal={
          /* if galaxies are not available and Meta is disconnected, disable generate new audiences button */
          availableGalaxies && !isMetaDisconnected ? toggleCreateSeedAudienceModal : undefined
        }
        onShowDeleteSeedAudienceModal={toggleSeedAudienceActionModal('delete')}
        onShowDeleteLookalikeAudienceModal={toggleLookalikeAudienceActionModal('delete')}
      />
      {/* Seed audience action modals */}
      <CreateSeedAudiencesModal
        show={createSeedAudiencesOpen}
        galaxies={galaxies}
        onClose={() => toggleCreateSeedAudienceModal(false)}
        onSeedAudiencesCreation={handleSeedAudiencesCreation}
      />
      <AudienceSettingsModal
        seedAudience={selectedSeedAudience}
        open={audienceSettingsModalOpen}
        onAudienceSettingsUpdate={handleAudienceSettingsUpdate}
        onClose={() => toggleSeedAudienceActionModal('settings')()}
      />
      <RenameSeedAudienceModal
        seedAudience={selectedSeedAudience}
        open={renameSeedAudienceModalOpen}
        onRenameAudience={handleRenameSeedAudience}
        onClose={() => toggleSeedAudienceActionModal('rename')()}
      />

      <DeleteSeedAudienceModal
        seedAudience={selectedSeedAudience}
        open={deleteSeedAudienceModalOpen}
        onDeleteAudience={handleDeleteSeedAudience}
        onClose={() => toggleSeedAudienceActionModal('delete')()}
      />
      {/* Lookalike audience action modals */}
      <CreateLookalikeAudienceModal
        seedAudience={selectedSeedAudience}
        open={createLookalikeAudiencesOpen}
        onCreateLookalikeAudience={handleLookalikeAudienceCreation}
        onClose={() => toggleSeedAudienceActionModal('createLookalike')()}
      />
      <RenameLookalikeAudienceModal
        lookalikeAudience={selectedLookalikeAudience}
        open={renameLookalikeAudienceModalOpen}
        onRenameAudience={handleRenameLookalikeAudience}
        onClose={() => toggleLookalikeAudienceActionModal('rename')()}
      />
      <DeleteLookalikeAudienceModal
        lookalikeAudience={selectedLookalikeAudience}
        open={deleteLookalikeAudienceModalOpen}
        onDeleteAudience={handleDeleteLookalikeAudience}
        onClose={() => toggleLookalikeAudienceActionModal('delete')()}
      />
    </>
  );
};

export default Audiences;

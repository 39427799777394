import styled from '@emotion/styled';
import { Checkbox, Text } from '@innovationdepartment/proxima-ui';

const SelectClearAllWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  & label {
    margin-right: 0;
  }
`;

const ClearAllWrapper = styled.div<{ onClick?: any }>`
  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'default')};
`;

type SelectClearAllProps = {
  onSelectAllClick: () => void;
  onClearAllClick: () => void;
  allSelected: boolean;
  anySelected: boolean;
};

const SelectClearAll = (props: SelectClearAllProps) => {
  const { onSelectAllClick, onClearAllClick, allSelected, anySelected } = props;

  return (
    <SelectClearAllWrapper>
      <Checkbox
        controlled
        key={`${allSelected}`}
        checked={allSelected}
        undetermined
        onChange={onSelectAllClick}
        label="Select All"
      />
      |
      <ClearAllWrapper onClick={anySelected ? onClearAllClick : undefined}>
        <Text
          variant="body2Medium"
          color={anySelected ? 'neutral90' : 'neutral60'}
          isTermina={false}
        >
          Clear Selection
        </Text>
      </ClearAllWrapper>
    </SelectClearAllWrapper>
  );
};

export default SelectClearAll;

import styled from '@emotion/styled';

const FilterBaseWrapperWrapper = styled.div`
  max-width: 905px;
`;

const FilterBaseWrapper: React.FC<{}> = ({ children }) => (
  <FilterBaseWrapperWrapper>{children}</FilterBaseWrapperWrapper>
);

export default FilterBaseWrapper;

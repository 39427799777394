import { CategoryFilterViewProps } from './types';
import CategoryFiltersCheckboxes from './CategoryFilter.Checkboxes';
import FilterBase from '../FilterBase';
import LoadingSpinner from 'ui/LoadingSpinner';

const CategoryFilter = (props: CategoryFilterViewProps) => {
  const {
    onClose,
    onOpen,
    open,
    label,
    category,
    allSelected,
    onCancelClick,
    onClearAllClick,
    onSaveClick,
    onSelectAllClick,
    loading,
  } = props;

  if (loading) return <LoadingSpinner />;

  return (
    <FilterBase
      sublabel="Categories"
      label={label}
      onClose={onClose}
      onOpen={onOpen}
      open={open}
      allSelected={allSelected}
      anySelected={category.length > 0}
      onCancelClick={onCancelClick}
      onClearAllClick={onClearAllClick}
      onSaveClick={onSaveClick}
      onSelectAllClick={onSelectAllClick}
    >
      <CategoryFiltersCheckboxes {...props} />
    </FilterBase>
  );
};

export default CategoryFilter;

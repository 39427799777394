import { Checkbox, colors } from '@innovationdepartment/proxima-ui';
import styled from '@emotion/styled';
import { CategoryFilterViewProps } from './types';

const CategoryFilterFiltersWrapper = styled.div`
  border-top: 1px solid ${colors.neutral20};
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 8px;
  margin-top: 8px;
  padding-top: 8px;
  overflow-y: auto;
  width: 468px;
  max-height: 280px;
`;

const CategoryFilterFilters = (props: CategoryFilterViewProps) => {
  const { category, flavors, onCategoryChange } = props;

  return (
    <CategoryFilterFiltersWrapper>
      {flavors.map((flavor) => (
        <Checkbox
          key={flavor.id}
          controlled
          label={flavor.name as string}
          variant="celes"
          checked={category.includes(flavor.id as string)}
          onChange={() => onCategoryChange(flavor.id as string)}
        />
      ))}
    </CategoryFilterFiltersWrapper>
  );
};

export default CategoryFilterFilters;

import styled from '@emotion/styled';
import { Text } from '@innovationdepartment/proxima-ui';
import { FilterBaseHeaderProps } from '../../types';
import SelectClearAll from '../SelectClearAll';

const FilterBaseHeaderWrapper = styled.div<{ order?: FilterBaseHeaderProps['order'] }>`
  display: flex;
  justify-content: space-between;
  ${({ order }) => {
    if (order === 'row') {
      return `
        padding-bottom: 16px;
        align-items: center;
      `;
    }

    return `
      flex-direction: column;
      gap: 16px;
    `;
  }}
`;

const FilterBaseHeader = (props: FilterBaseHeaderProps) => {
  const { onSelectAllClick, onClearAllClick, sublabel, allSelected, anySelected, order } = props;

  return (
    <FilterBaseHeaderWrapper order={order}>
      <Text variant="h6" color="neutral80" isTermina={false}>
        {sublabel}
      </Text>
      <SelectClearAll
        onSelectAllClick={onSelectAllClick}
        onClearAllClick={onClearAllClick}
        allSelected={allSelected}
        anySelected={anySelected}
      />
    </FilterBaseHeaderWrapper>
  );
};

export default FilterBaseHeader;

import styled from '@emotion/styled';
import {
  Heatmap,
  SelectInput,
  SelectOption,
  Table,
  TableProps,
  Text,
  colors,
  getColorInRange,
} from '@innovationdepartment/proxima-ui';
import trendsRowRenderer from './trendsRowRenderer';
import trendsFooterRenderer from './trendsFooterRenderer';
import { HeatmapViewIntensity, InsightsColumnData } from 'types/insights';
import TrendsLoading from '../Layout/Trends.Loading';

const TableContainer = styled.div`
  border: 1px solid ${colors.neutral20};
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  > div:has(table) {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
`;

const DropdownContainer = styled.div`
  display: flex;
  padding: 16px 12px;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const IconLabelContainer = styled.div`
  display: flex;
  gap: 4px;
  justify-content: center;
  align-items: center;
`;

const TooltipContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 12px 7px;
  width: 176px;
`;

const PercentChangeTooltip = (
  <TooltipContainer>
    <Text variant="body2Semibold">% Change</Text>
    <Text variant="body3">
      Left Column (All Brands): The aggregated change in performance across all brands in the
      selected category compared to the previous period.
    </Text>
    <Text variant="body3">
      Right Column (Your Brand): The change in your brand&apos;s performance compared to the
      previous period.
    </Text>
  </TooltipContainer>
);

type TrendsTableProps = {
  insightsData: InsightsColumnData[];
  intervalLabel: string;
  flavorCategory: string;
  heatmapViewIntesity: HeatmapViewIntensity;
  flavorCategoryOptions: SelectOption[];
  heatmapViewIntesityOptions: SelectOption[];
  onFlavorCategoryChanged: (flavorCategory: string) => void;
  onHeatmapViewIntesityChanged: (view: HeatmapViewIntensity) => void;
};

// all values are in hsl
const colorValues: { bright: number[][]; light: number[][] } = {
  bright: [
    [136, 50, 59], // green
    [35, 69, 81], // orange
    [5, 70, 71], // red
  ],
  light: [
    [136, 45, 77], // green
    [36, 59, 88], // orange
    [10, 62, 84], // red
  ],
};

const TrendsTable = (props: TrendsTableProps) => {
  const {
    insightsData,
    intervalLabel,
    flavorCategory,
    heatmapViewIntesity,
    flavorCategoryOptions,
    heatmapViewIntesityOptions,
    onFlavorCategoryChanged,
    onHeatmapViewIntesityChanged,
  } = props;

  const dateHeader = () => {
    const isIntervalWeek = intervalLabel === 'Week';

    if (isIntervalWeek) {
      return {
        label: intervalLabel,
        width: 114,
        tooltip: {
          title: (
            <TooltipContainer>
              <Text variant="body2Semibold">Week</Text>
              <Text variant="body3">Week starts on Sunday.</Text>
            </TooltipContainer>
          ),
        },
      };
    }

    return {
      label: intervalLabel,
      width: 114,
    };
  };

  const config: TableProps<InsightsColumnData>['config'] = {
    columns: {
      order: ['date', 'cpm', 'ctr', 'cpa', 'roas', 'cpc', 'cpaChange', 'roasChange'],
      date: dateHeader(),
      cpm: {
        label: 'CPM',
        align: 'right',
        width: 166,
      },
      ctr: {
        label: 'CTR (all)',
        align: 'right',
        width: 166,
      },
      cpa: {
        label: 'CPA',
        align: 'right',
        width: 166,
      },
      roas: {
        label: 'ROAS',
        align: 'right',
        width: 166,
      },
      cpc: {
        label: 'CPC',
        align: 'right',
        width: 166,
      },
      cpaChange: {
        label: 'CPA % Change',
        align: 'center',
        width: 166,
        tooltip: {
          title: PercentChangeTooltip,
        },
      },
      roasChange: {
        label: 'ROAS % Change',
        align: 'center',
        width: 166,
        tooltip: {
          title: PercentChangeTooltip,
        },
      },
    },
    cellColor: (cell) => {
      const { header } = cell;
      const columnsWithColors = ['cpm', 'ctr', 'cpa', 'roas', 'cpc'];

      if (columnsWithColors.includes(header as keyof InsightsColumnData)) {
        const indName = header as keyof InsightsColumnData;
        const isCtrOrRoas = ['ctr', 'roas'].includes(header!);

        const allNumbers = insightsData.map((row) => row[indName] as number);

        const bottomOfRange = isCtrOrRoas ? Math.max(...allNumbers) : Math.min(...allNumbers);
        const topOfRange = isCtrOrRoas ? Math.min(...allNumbers) : Math.max(...allNumbers);

        const rowBalance = cell.row![indName] ? (cell.row![indName] as number) : 0;
        const percentage = ((rowBalance - bottomOfRange) / (topOfRange - bottomOfRange)) * 100;

        if (Number.isNaN(percentage) || percentage.toString() === 'NaN') return 'FFF';

        return getColorInRange(
          percentage,
          colorValues[heatmapViewIntesity as keyof typeof colorValues],
        ).hex;
      }
      return undefined;
    },
    cellRenderer(row) {
      return trendsRowRenderer({ ...row });
    },
    footerRenderer(row) {
      return trendsFooterRenderer({ ...row });
    },
    // eslint-disable-next-line
    emptyStateRenderer() {
      // doing this because of eslint rules: https://github.com/react-navigation/react-navigation/issues/11536#issuecomment-1682119691
      return <TrendsLoading />;
    },
  };

  const flavorCategoryLabel = flavorCategoryOptions.find(
    (option) => option.value === flavorCategory,
  )!.label;
  const heatmapViewIntesityLabel =
    heatmapViewIntesity.charAt(0).toUpperCase() + heatmapViewIntesity.slice(1);

  return (
    <TableContainer>
      <DropdownContainer>
        <SelectInput
          options={flavorCategoryOptions}
          size="small"
          value={flavorCategory}
          renderValue={() => <Text variant="body2Semibold">Category: {flavorCategoryLabel}</Text>}
          onChange={(newFlavorCategory) => onFlavorCategoryChanged(newFlavorCategory)}
        />
        <SelectInput
          options={heatmapViewIntesityOptions}
          size="small"
          value={heatmapViewIntesity}
          renderValue={() => (
            <IconLabelContainer>
              <Heatmap />
              <Text variant="body2Semibold">View: {heatmapViewIntesityLabel}</Text>
            </IconLabelContainer>
          )}
          onChange={(newHeatmapView) =>
            onHeatmapViewIntesityChanged(newHeatmapView as HeatmapViewIntensity)
          }
        />
      </DropdownContainer>
      <Table data={insightsData} config={config} variant="celes" />
    </TableContainer>
  );
};

export default TrendsTable;

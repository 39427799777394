import { BrandItem } from '@innovationdepartment/proxima-sdk-axios';
import { AdData } from 'types/fbTable';

export type CreativeAsset = {
  id: number;
  brandId: string;
  adAccountId: string;
  permalinkType: string;
  permalinkUrl: string;
  createdAt: string;
  updatedAt: string;
};

export enum CreativeType {
  PHOTO = 'POST_DELETED,SHARE,PRIVACY_CHECK_FAIL,PAGE,STATUS,DOMAIN,PHOTO', // These values must match those other than VIDEO
  VIDEO = 'VIDEO',
}

export enum CreativeAdStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'PAUSED,ARCHIVED', // these values must match those other than ACTIVE
}

export type CreativeAd = AdData<{
  brand?: BrandItem;
  loading?: boolean;
  category?: string;
  launchDate?: string;
  spendDays?: string;
  lifetimeSpend?: string;
  ctr: number;
  purchaseRoasCategoryQuentile: number;
  ctrCategoryQuentile: number;
  purchaseRoasBrandIdQuentile: number;
  ctrBrandIdQuentile: number;
  cpaCategoryQuentile: number;
  cpaBrandQuentile: number;
  cpaBrandIdQuentile: number;
  thumbstopRate?: number;
  creativeType?: CreativeType;
  creativeAssets?: CreativeAsset[];
}>['data'][0];

export type CreativeAdSummary = {
  costPerResult: number;
  costPerResultLabel: string;
  cpc: number;
  impressions: number;
  spend: number;
};

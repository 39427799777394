import styled from '@emotion/styled';
import {
  AllowedColors,
  colors,
  ImageIcon,
  Text,
  VideoIcon,
} from '@innovationdepartment/proxima-ui';
import BadCheck from 'components/PerformanceAds/Layout/elements/BadCheck';
import OkCheck from 'components/PerformanceAds/Layout/elements/OkCheck';
import { CreativeAdStatus, CreativeType } from 'components/PerformanceAds/Layout/types';

const COLOR_MAP: { [key: string]: { label: string; color: AllowedColors } } = {
  5: { label: 'Best', color: 'green50' },
  4: { label: 'Good', color: 'blue50' },
  3: { label: 'Average', color: 'yellow50' },
  2: { label: 'Poor', color: 'orange50' },
  1: { label: 'Worst', color: 'red50' },
};

const ColorLabelWrapper = styled.div<{ color: AllowedColors }>`
  position: relative;
  margin-left: 16px;

  &::before {
    left: -16px;
    top: calc(50% - 6px);
    position: absolute;
    content: '';
    height: 12px;
    width: 12px;
    border-radius: 50%;
    background-color: ${({ color }) => colors[color]};
  }
`;

export const bestToWorstRenderer = (value: string) => {
  const { color, label } = COLOR_MAP[value];

  return (
    <ColorLabelWrapper color={color}>
      <Text variant="tag2Semibold" color="neutral90" isTermina={false}>
        {label}
      </Text>
    </ColorLabelWrapper>
  );
};

const ElementsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const creativeTypeRenderer = (value: string) => {
  let icon = <ImageIcon />;
  let label = 'Static';

  if (value === CreativeType.VIDEO) {
    icon = <VideoIcon />;
    label = 'Video';
  }

  return (
    <ElementsWrapper>
      {icon}
      <Text variant="tag2Semibold" color="neutral90" isTermina={false}>
        {label}
      </Text>
    </ElementsWrapper>
  );
};

export const statusRenderer = (value: string) => {
  let label = 'Active';

  if (value === CreativeAdStatus.INACTIVE) label = 'Inactive';

  const icon = {
    Active: <OkCheck />,
    Inactive: <BadCheck />,
  }[label];

  return (
    <ElementsWrapper>
      {icon}
      <Text variant="tag2Semibold" color="neutral90" isTermina={false}>
        {label}
      </Text>
    </ElementsWrapper>
  );
};

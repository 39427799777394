import { useEffect, useRef, useState } from 'react';
import CategoryFilterView from './CategoryFilter.View';
import { CategoryFilterProps } from './types';
import { useProximaSDK, useQuery } from 'hooks';
import { FlavorCategory } from '@innovationdepartment/proxima-sdk-axios';

const MODAL_TIMEOUT = 250;

const formatFilter = (filter: string) => (filter ?? '').split(',');

const CategoryFilter = (props: CategoryFilterProps) => {
  const { getQueryParams } = useQuery();
  const { category: categoryQuery } = getQueryParams<'category'>();
  const audiencesApi = useProximaSDK('AudiencesApi');
  const { open, onClose, onOpen, onSaveSelection } = props;
  const previousValues = useRef<any>(null);
  const [category, setCategory] = useState<string[]>(
    categoryQuery ? formatFilter(categoryQuery) : [],
  );
  const [flavors, setFlavors] = useState<FlavorCategory[]>([]);

  const onSelectAllClick = () => setCategory([...flavors.map((flavor) => flavor.id)] as string[]);

  const onClearAllClick = () => setCategory([]);

  const onCancelClick = () => {
    setTimeout(() => setCategory(previousValues.current), MODAL_TIMEOUT);
    onClose();
  };

  const setPreviousValues = () => {
    previousValues.current = category;
  };

  const onSaveClick = () => {
    const filters: { [key in 'category']?: string[] } = { category: undefined };

    if (category.length > 0) filters.category = category;

    onSaveSelection(filters);
    setPreviousValues();
    onClose();
  };

  const onCategoryChange = (cat: string) => {
    if (category.includes(cat)) setCategory(category.filter((c) => c !== cat));
    else setCategory([...category, cat].sort());
  };

  /* sets previous values, when calling cancel button */
  useEffect(() => {
    if (open) previousValues.current = category;
  }, [open]);

  /* fetch all flavor categories */
  useEffect(() => {
    const fetchFlavorCategories = async () => {
      const response = await audiencesApi.getFlavorCategories();
      setFlavors(response.data);

      /* if no category is loaded, set category to all */
      if (!categoryQuery) setCategory(response.data.map((flavor) => flavor.id) as string[]);
    };

    fetchFlavorCategories();
  }, []);

  const isAllSelected = flavors.every((flavor) => category.includes(flavor.id as string));

  const label = `Category / ${isAllSelected ? 'All' : category.length}`;

  return (
    <CategoryFilterView
      loading={audiencesApi.loading}
      open={open}
      onOpen={onOpen}
      onClose={onClose}
      onCategoryChange={onCategoryChange}
      flavors={flavors}
      category={category}
      label={label}
      onSelectAllClick={onSelectAllClick}
      onClearAllClick={onClearAllClick}
      allSelected={isAllSelected}
      onSaveClick={onSaveClick}
      onCancelClick={onCancelClick}
    />
  );
};

export default CategoryFilter;

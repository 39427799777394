import { QuickFiltersViewProps } from './types';
import QuickFiltersFilters from './Layout/QuickFilters.Filters';
import FilterBase from '../FilterBase';
import { AdvancedFilters } from '@innovationdepartment/proxima-ui';

const QuickFiltersView = (props: QuickFiltersViewProps) => {
  const {
    onClose,
    onOpen,
    open,
    label,
    allSelected,
    anySelected,
    onCancelClick,
    onClearAllClick,
    onSaveClick,
    onSelectAllClick,
  } = props;

  return (
    <FilterBase
      sublabel="Quick Filters"
      icon={<AdvancedFilters />}
      label={label}
      onClose={onClose}
      onOpen={onOpen}
      open={open}
      allSelected={allSelected}
      anySelected={anySelected}
      onCancelClick={onCancelClick}
      onClearAllClick={onClearAllClick}
      onSaveClick={onSaveClick}
      onSelectAllClick={onSelectAllClick}
    >
      <QuickFiltersFilters {...props} />
    </FilterBase>
  );
};

export default QuickFiltersView;

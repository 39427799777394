import { BrandInsight, FlavorCategoryInsight } from '@innovationdepartment/proxima-sdk-axios';
import { InsightQueryStringParams, InsightsInterval, IntervalOption } from 'types/insights';

export const intervalOptions: IntervalOption[] = [
  {
    label: 'Day',
    value: InsightsInterval.Day,
  },
  {
    label: 'Week',
    value: InsightsInterval.Week,
  },
  {
    label: 'Month',
    value: InsightsInterval.Month,
  },
];

export const checkFlavorCategoryValue = (flavorCategoryId: string) => {
  // we need this because these are the only flavor categories that have data
  // for brands in a different category we use the `all` keyword to retun
  // data for them instead of no values
  const includedFlavorCategories = ['FASH', 'BEAU', 'HOBB', 'HEAL'];
  const useAllKeyword = !includedFlavorCategories.includes(flavorCategoryId);
  const flavorCategoryIdParam = useAllKeyword ? 'all' : flavorCategoryId;

  return flavorCategoryIdParam;
};

// This is to fill in any missing values in the brand data for dates we have flavor category data for
export const fillBrandData = (
  brandInsights: BrandInsight[],
  flavorCategoryInsights: FlavorCategoryInsight[],
) => {
  const brandDates = new Map();

  brandInsights.forEach((brandData) => {
    brandDates.set(brandData.date, brandData);
  });

  return flavorCategoryInsights.map((flavorData) => {
    if (brandDates.has(flavorData.date)) return brandDates.get(flavorData.date);
    return { date: flavorData.date, value: 0 };
  });
};

export const updateQueryStringParams = ({ key, value }: InsightQueryStringParams) => {
  // Query string has to be updated like this because setSearchParams causes an unnecessary page re-render and seems to be a known bug: https://github.com/remix-run/react-router/discussions/9851
  const url = new URL(window.location.href);
  url.searchParams.set(key, value);
  window.history.pushState({}, '', url.toString());
};

export const dollarUnitSymbol = (header: string) =>
  ['cpm', 'cpa', 'cpc'].includes(header!) ? '$' : '';
export const percentUnitSymbol = (header: string) => (header! === 'ctr' ? '%' : '');

import styled from '@emotion/styled';
import { SpinningLoader, Text } from '@innovationdepartment/proxima-ui';

const TrendsLoadingContainer = styled.div`
  display: flex;
  gap: 26px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 376px;
  width: 100%;
`;

const TrendsLoading = () => (
  <TrendsLoadingContainer>
    <SpinningLoader />
    <Text variant="h4" color="neutral100">
      Loading insights...
    </Text>
  </TrendsLoadingContainer>
);

export default TrendsLoading;

import { useEffect, useRef, useState } from 'react';
import BrandFilterView from './BrandFilter.View';
import { BrandFilterProps, BrandFilterViewProps, SelectedBrandItem } from './types';
import { BrandItem } from '@innovationdepartment/proxima-sdk-axios';
import { useBrandElasticSearchApi, useProximaSDK, useQuery } from 'hooks';
import { debounce } from 'lodash';

type SelectedBrands = BrandFilterViewProps['selectedBrands'];

const DEBOUNCER_TIMEOUT = 500;
const debouncer = debounce((cb) => cb(), DEBOUNCER_TIMEOUT);

const BrandFilter = (props: BrandFilterProps) => {
  const semaphorRef = useRef(false);
  const listViewRef = useRef<HTMLDivElement>(null);
  const { open, onOpen, onClose, onSaveSelection } = props;
  const [focused, setFocused] = useState(false);
  const [selectedBrands, setSelectedBrands] = useState<SelectedBrands>(new Map());
  const [prevSelectedBrands, setPrevSelectedBrands] = useState<SelectedBrands>(new Map());
  const [brands, setBrands] = useState<BrandItem[]>([]);
  const { getBrands, getNextBrandBatch, hasNext, loading } = useBrandElasticSearchApi();
  const brandsApi = useProximaSDK('BrandsApi');
  const [searchCriteria, setSearchCriteria] = useState('');
  const [rerender, setRerender] = useState(Date.now());
  const { getQueryParams } = useQuery();

  const noResults = searchCriteria.length > 0 && brands.length === 0;

  const resetEverything = () => {
    semaphorRef.current = false;
    setSearchCriteria('');
    setTimeout(() => {
      setBrands([]);
      setFocused(false);
      setRerender(Date.now());
    }, 250);
  };

  const onCloseFilter = () => {
    onClose();
    resetEverything();
  };

  const onCancelClick = () => {
    onCloseFilter();
    setTimeout(() => {
      setSelectedBrands(prevSelectedBrands);
    }, 250);
  };

  const onBrandSelect = (brand: SelectedBrandItem) => {
    if (!brand.brandId) return;

    setSelectedBrands((prev) => {
      const next = new Map(prev);
      if (next.has(brand.brandId!)) {
        next.delete(brand.brandId!);
        return next;
      }

      next.set(brand.brandId!, brand);
      return next;
    });
  };

  const onSaveClick = () => {
    onSaveSelection({ brandId: Array.from(selectedBrands.keys()) });
    onCloseFilter();
  };

  const fetchNextBrands = async (criteria: string, newSearch: boolean = true) => {
    if (criteria.length === 0) return;
    if (loading) return;
    if (newSearch) {
      const nextBrands = await getBrands({ search: criteria });
      listViewRef.current?.scrollTo({ top: 0 });
      setBrands(nextBrands);
      return;
    }

    const nextBrands = await getNextBrandBatch({ search: criteria });
    setBrands((prevBrands) => [...prevBrands, ...nextBrands]);
  };

  const onClearAllBrands = () => {
    setSelectedBrands(new Map());
  };

  const onBackClick = () => {
    resetEverything();
  };

  const onDebounceRequestNextBatch = async (criteria: string, newSearch = true) => {
    debouncer(() => fetchNextBrands(criteria, newSearch));
  };

  const onSearchCriteriaChange = (criteria: string) => {
    setSearchCriteria(criteria);
    if (criteria.length === 0) {
      setBrands([]);
      return;
    }
    onDebounceRequestNextBatch(criteria);
  };

  useEffect(() => {
    if (!open) {
      resetEverything();
    } else {
      setPrevSelectedBrands(selectedBrands);
    }
  }, [open]);

  useEffect(() => {
    const { brandId } = getQueryParams<'brandId'>();
    if (brandId) {
      const getBrandsOnLoad = async () => {
        const response = await brandsApi.getBrandBatch({ brandId });
        setBrands(response.data);
        setSelectedBrands(
          new Map(response.data.map((brand) => [brand.brandId, brand])) as SelectedBrands,
        );
      };
      getBrandsOnLoad();
    }
  }, []);

  return (
    <BrandFilterView
      open={open}
      rerender={rerender}
      onGetNextBrandBatch={() => {
        onDebounceRequestNextBatch(searchCriteria, false);
      }}
      onClearAllBrands={onClearAllBrands}
      hasNext={hasNext}
      noResults={noResults}
      brands={brands}
      loading={loading}
      searchCriteria={searchCriteria}
      setSearchCriteria={onSearchCriteriaChange}
      selectedBrands={selectedBrands}
      onBrandSelect={onBrandSelect}
      onCancelClick={onCancelClick}
      onClose={onCancelClick}
      onSaveClick={onSaveClick}
      onOpen={onOpen}
      focused={focused}
      onFocus={() => setFocused(true)}
      onBackClick={onBackClick}
      listViewRef={listViewRef}
    />
  );
};

export default BrandFilter;

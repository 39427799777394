import { useLocation } from 'react-router-dom';

export default function useQuery() {
  const location = useLocation();

  const getQueryParams = <T>() => {
    const urlSearchParams = new URLSearchParams(location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    return params as { [key in T extends string ? T : string]?: string };
  };

  const setQueryParams = (
    params: Record<string, string | string[] | undefined>,
    replace = true,
  ) => {
    const urlSearchParams = new URLSearchParams(replace ? '' : location.search);
    Object.entries(params).forEach(([key, value]) => {
      if (value) urlSearchParams.set(key, Array.isArray(value) ? value.join(',') : value);
    });
    const { history } = window;
    if (history && history.pushState) {
      const newUrl = `${location.pathname}?${urlSearchParams.toString()}`;
      history.pushState({}, '', newUrl);
    }
  };

  return {
    setQueryParams,
    getQueryParams,
  };
}
